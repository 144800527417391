import Slider from "react-slick";
import styled from 'styled-components';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { getApiUrl } from '../../config';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;
const StyledSlider = styled(Slider)`
  .slick-slide > div {
    padding: 0;
  }

  .slick-prev, .slick-next {
    width: 24px;  /* Increased from 24px */
    height: 24px;  /* Increased from 24px */
    background: rgba(17, 17, 17, 0.3);
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1;
    
    &:before {
      display: none;
    }

    &:hover {
      background: rgba(17, 17, 17, 0.6);
    }
  }

  .slick-prev {
    left: 8px;
          display: none !important;

  }

  .slick-next {
    right: 8px;
  }
`;

const ArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.5);  /* Made the arrows gray and transparent */
`;

const Card = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  overflow: hidden;
`;

const renderStars = (rating) => {
    const roundedRating = (rating % 1 > 0.5)
        ? Math.ceil(rating)
        : (rating % 1 === 0.5)
            ? Math.floor(rating) + 0.5
            : Math.floor(rating);

    const fullStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 === 0.5;

    return (<div className="stars">
            {'★'.repeat(fullStars)}
            {hasHalfStar ? '½' : ''}
            {'☆'.repeat(5 - fullStars - (hasHalfStar ? 1 : 0))}
        </div>);
};

const ReviewWidget = ({name}) => {
    const [reviews, setReviews] = useState([]);
    const [rating, setRating] = useState(0);
    const [totalReviews, setTotalReviews] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${getApiUrl()}/get-reviews?business_name=${name}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch reviews');
                }
                const data = await response.json();
                setReviews(data.reviews);
                setRating(data.rating);
                setTotalReviews(data.total_reviews);
            } catch (err) {
                setError(err.message);
                // Fallback values
                setRating(0);
                setTotalReviews(0);
            } finally {
                setLoading(false);
            }
        };

        if(name)
            fetchReviews();
    }, []);

    if (totalReviews === 0) return <div></div>;
    if (loading) return <div></div>;
    if (error) return <div></div>;

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <div><ArrowButton><IoChevronBack size={16} /></ArrowButton></div>,
        nextArrow: <div><ArrowButton><IoChevronForward size={16} /></ArrowButton></div>
    };

    return (
        <SliderContainer>
            <h2
                className="lc-1qcaodq e184w8cj5"
                style={{
                    boxSizing: "border-box",
                    fontSize: 16,
                    fontWeight: "bold",
                    padding: "0px 16px",
                    marginTop: 16,
                    marginBottom: 16,
                    color: "rgb(17, 17, 17)"
                }}
            >
                <div
                    className="lc-1laupg6 efrsfol0"
                    style={{
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center"
                    }}
                >  <FaStar style={{ marginRight: 6 }} />
                    Asiakasarvostelut
                </div>
            </h2>

            <Card>
                <div style={{
                    padding: '15px',
                    textAlign: 'center',
                    borderBottom: '1px solid #eee'
                }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        className="injected-svg"
                        style={{ width: 24, height: 24 }}
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <path
                            fill="#2A84FC"
                            d="M21.579 12.234c0-.677-.055-1.359-.172-2.025h-9.403v3.839h5.384a4.615 4.615 0 0 1-1.992 3.029v2.49h3.212c1.886-1.736 2.97-4.3 2.97-7.333Z"
                        />
                        <path
                            fill="#00AC47"
                            d="M12.004 21.974c2.688 0 4.956-.882 6.608-2.406l-3.213-2.491c-.893.608-2.047.952-3.392.952-2.6 0-4.806-1.754-5.597-4.113H3.095v2.567a9.97 9.97 0 0 0 8.909 5.491Z"
                        />
                        <path
                            fill="#FFBA00"
                            d="M6.407 13.916a5.971 5.971 0 0 1 0-3.817V7.531H3.095a9.977 9.977 0 0 0 0 8.953l3.312-2.568Z"
                        />
                        <path
                            fill="#FC2C25"
                            d="M12.004 5.982a5.417 5.417 0 0 1 3.824 1.494l2.846-2.846a9.581 9.581 0 0 0-6.67-2.593A9.967 9.967 0 0 0 3.095 7.53l3.312 2.57c.787-2.363 2.996-4.117 5.597-4.117Z"
                        />
                    </svg>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                    }}>
                        <span style={{
                            fontSize: '28px',
                            fontWeight: 'bold',
                            marginTop: 4
                        }}>
                            {rating}
                        </span>
                        <br />
                        <div style={{
                            color: '#fcbf02',
                            fontSize: '38px',
                            letterSpacing: '0.2px'
                        }}>
                            {renderStars(rating)}
                        </div>
                        <span style={{
                            color: '#666',
                            fontSize: '12px',
                            marginTop: 8
                        }}>
                            ({totalReviews})
                        </span>
                    </div>
                </div>

                <StyledSlider {...sliderSettings}>
                    {reviews.map((review, index) => (
                        <div key={index}>
                            <div style={{ padding: '15px' }}>
                                <div style={{
                                    color: '#fcbf02',
                                    marginBottom: '6px',
                                    fontSize: '24px',
                                    letterSpacing: '1px'
                                }}>
                                    {"★".repeat(review.rating)}
                                </div>
                                <p style={{
                                    fontSize: '14px',
                                    margin: '0 0 8px 0',
                                    color: '#333',
                                    lineHeight: '1.4',
                                    height: 'calc(1.4em * 2)', // Fixed height for 2 lines
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {review.text}
                                </p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    marginBottom: '4px'
                                }}>
                                    <div style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        background: '#e0e0e0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '11px'
                                    }}>
                                        {review.author[0]}
                                    </div>
                                    <span style={{
                                        fontSize: '13px',
                                        fontWeight: '500'
                                    }}>
                                        {review.author}
                                    </span>
                                    <span style={{
                                        fontSize: '12px',
                                        color: '#666'
                                    }}>
                                        {review.time}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </StyledSlider>


            </Card>
        </SliderContainer>
    );
};

export default ReviewWidget;