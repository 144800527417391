import React from 'react';
import { useState, useEffect } from 'react';
import { Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPhone,
    faEnvelope,
    faComment,
    faComments,
    faPaperPlane,
    faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import HelpCenter from './HelpCenter';
import ReviewWidget from './ReviewWidget';
import {
    FaComment, FaArrowLeft, FaHome, FaQuestionCircle,
    FaSearch, FaEnvelope, FaRedo, FaTimes
} from 'react-icons/fa';
import ContactForm from './ContactFormDefault';
import './openwidget.css';
import { getApiUrl } from '../../config';
import ChatSimple from './ChatSimple';
const STORAGE_KEYS = {
    WIDGET_STATE: 'virtuaaliApuri_widgetState',
    INNER_WINDOW: 'virtuaaliApuri_innerWindow',
};

import "bootstrap/dist/css/bootstrap.css";


const FloatingFooter = ({ isVisible, onChange, activeColor }) => {
    const [active, setActive] = useState('Koti');

    useEffect(() => {
        if (onChange) {
            onChange(active); // Call the onChange function when the active tab changes
        }
    }, [active, onChange]);

    const footerStyle = {
        position: 'absolute',
        bottom: 12,
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth: '334px',
        minWidth: '334px',
        display: isVisible ? 'flex' : 'none', // Show footer based on scroll position
        justifyContent: 'space-around',
        backgroundColor: '#fff',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        padding: '10px 0',
        borderTop: '1px solid #ddd',
        zIndex: 99999,
        borderRadius: 24,
        background: 'rgb(255 255 255 / 99%)',
    };

    const buttonStyle = (isActive) => ({
        flex: 1,
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: isActive ? 'bold' : 'normal',
        color: isActive ? activeColor ?? '#c90606' : '#666',
        padding: '4px 0'
    });

    const iconStyle = {
        fontSize: '18px',
        marginBottom: '2px',
    };

    return (
        <div className="widgetFooter" style={footerStyle}>
            <div style={buttonStyle(active === 'Koti')} onClick={() => setActive('Koti')}>
                <FaHome style={iconStyle} />
            </div>
            <div style={buttonStyle(active === 'Yhteys')} onClick={() => setActive('Yhteys')}>
                <FaEnvelope style={iconStyle} />
            </div>
            <div style={buttonStyle(active === 'Apu')} onClick={() => setActive('Apu')}>
                <FaQuestionCircle style={iconStyle} />
            </div>
        </div>
    );
};


<style>
    {`
                @keyframes slideUp {
                    from {
                        opacity: 0;
                        transform: translateY(20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            `}
</style>
const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
Textarea.displayName = 'Textarea';

export function VirtuaaliApuriWidget({ onToggle, id, onClickOffer, businessInformation }) {
    const [widgetData, setWidgetData] = React.useState({});
    const [showButton, setShowButton] = React.useState(false);
    const [openingMessage, setOpeningMessage] = React.useState(false);
    const [innerWindowOpen, setInnerWindowOpen] = React.useState(false);
    const [currentActiveWindow, setCurrentActiveWindow] = useState("home");
    const [chatKey, setChatKey] = useState(0);

    const handleRestartChat = () => {
        setChatKey(prevKey => prevKey + 1);
    };

    // Load initial states from localStorage
    const [isMinimized, setIsMinimized] = React.useState(() => {
        const saved = localStorage.getItem(STORAGE_KEYS.WIDGET_STATE);
        return saved ? JSON.parse(saved) : true;
    });

    // Save states to localStorage when they change
    React.useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.WIDGET_STATE, JSON.stringify(isMinimized));
    }, [isMinimized]);

    // Show button after delay
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    // Fetch widget data
    React.useEffect(() => {
        const fetchWidgetData = async () => {
            try {
                if (!id) return null;
                const response = await fetch(`${getApiUrl()}/getBotInfo/${id}`);
                if (!response.ok) return;
                const botData = await response.json();
                setWidgetData(botData);
            } catch (e) {
                console.error('Error fetching bot data:', e);
            }
        };

        if (businessInformation) {
            setWidgetData(businessInformation);
        } else {
            fetchWidgetData();
        }
    }, [id, businessInformation]);

    React.useEffect(() => {
        if (!widgetData?.custom_css) return;

        // Create style element
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.setAttribute('id', `widget-custom-css-${widgetData.bot_id}`);

        // Add a wrapper class to ensure styles are scoped to our widget
        const wrappedCSS = `
            .virtuaali-apuri-widget {
                ${widgetData.custom_css}
            }
        `;

        styleElement.textContent = wrappedCSS;

        // Add to document head
        document.head.appendChild(styleElement);

        // Cleanup on unmount
        return () => {
            const existingStyle = document.getElementById(`widget-custom-css-${widgetData.bot_id}`);
            if (existingStyle) {
                document.head.removeChild(existingStyle);
            }
        };
    }, [businessInformation?.custom_css, businessInformation?.bot_id, widgetData?.bot_id]);

    const handleMinimize = () => {
        setIsMinimized(!isMinimized);
        setInnerWindowOpen(false);
        onToggle && onToggle(!isMinimized);
    };

    if (!widgetData?.bot_id) {
        return <></>
    }

    let customStyles = {};
    const name = widgetData?.title;
    const onChangeFooter = (activeWindow) => {
        setCurrentActiveWindow(activeWindow)
    }

    return (
        <div className="virtuaali-apuri-widget">
            <button
                className={"widgetButton"}
                onClick={handleMinimize}
                style={{
                    position: 'fixed',
                    bottom: '30px',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: widgetData?.theme_color ?? '#37474F',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
                    opacity: showButton ? 1 : 0,
                    transform: showButton ? 'translateY(0)' : 'translateY(20px)',
                    transition: 'opacity 0.4s ease, transform 0.4s ease',
                    zIndex: 9999999,
                    ...customStyles
                }}
            >
                <FaComment size="22" color='white'></FaComment>
            </button>

            <div
                className="ow-159tj6x e1ujklng0 maincontianer"
                style={{
                    width: "100%",
                    height: "100%",
                    minWidth: "0px",
                    margin: "0px",
                    padding: "0px",
                    backgroundImage: "none",
                    backgroundPosition: "0% 0%",
                    backgroundSize: "initial",
                    backgroundAttachment: "scroll",
                    backgroundOrigin: "initial",
                    backgroundClip: "initial",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                    float: "none",
                    colorScheme: "normal",
                    position: "fixed",
                    right: "0px",
                    display: "block",
                    visibility: "visible",
                    opacity: 1,
                    transform: isMinimized ? 'translateY(110%)' : 'translateY(0%)', // Slide up/down based on state
                    transition: 'transform 0.4s ease', // Smooth slide transition
                    pointerEvents: isMinimized ? 'none' : 'auto',
                    maxWidth: 380,
                    minHeight: "300px",
                    bottom: "0px",
                    zIndex: 9999999,
                }}
            >

                <div
                    className="ow-1ttnkpf e1ujklng1"
                    style={{ width: "100%", height: "100%" }}
                >
                    <div id="widget-global-nd6bpniszq" style={{ boxSizing: "border-box" }}>
                        <div
                            data-testid="maximized"
                            id="chat-widget-maximized"
                            dir="ltr"
                            className="lc-1dvk0j5 edvz03i3 lc-12vmhyu-appear-done lc-12vmhyu-enter-done"
                            style={{

                                position: "absolute",
                                inset: "0px 0px 0px auto",
                                width: "100%",
                                height: "100%",
                            }}
                        >

                            <div
                                role="main"
                                className="lc-1483f3g edvz03i2"
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    minWidth: 0,
                                    height: "100%",
                                    overflow: "auto",
                                    backgroundPosition: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                    backgroundColor: "white",
                                    border: "1px solid #bfb5b5",
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div
                                    id="overlay_portal_container"
                                    className="lc-l7pcb5 e196iwy70"
                                    style={{


                                        zIndex: 102,
                                        borderRadius: "inherit"
                                    }}
                                />
                                <div
                                    id="tooltip_portal_container"
                                    className="lc-2gmwhl e196iwy71"
                                    style={{


                                        zIndex: 102,
                                        position: "relative"
                                    }}
                                />
                                <span
                                    data-focus-scope-start="true"
                                    hidden=""
                                />
                                <div
                                    className="lc-6vuy1u e10snzb05"
                                    style={{
                                        position: "sticky",
                                        width: "100%",
                                        zIndex: 2,
                                        top: 0,
                                        background: widgetData?.theme_color_header ?? 'transparent',
                                        boxShadow: widgetData?.theme_color_header ? '0px 3px 15px rgb(0 0 0 / 18%)' : 'none',
                                        //height: innerWindowOpen ? '164px' : '64px',
                                        height: '64px',
                                        transition: innerWindowOpen
                                            ? 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)' // expanding
                                            : 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)', // shrinking
                                    }}
                                ><div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        width: "85%",
                                        padding: "0px 0",
                                        position: 'absolute',
                                        top: '4px'
                                    }}
                                >
                                        {widgetData?.logo?.length > 5 ? (
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                position: 'relative'
                                            }}>
                                                {innerWindowOpen && (
                                                    <FaArrowLeft
                                                        onClick={() => {
                                                            setInnerWindowOpen(false);
                                                        }}
                                                        style={{
                                                            fontSize: 20,
                                                            color: '#fff',
                                                            marginLeft: 14,
                                                            marginTop: 16,
                                                            cursor: 'pointer',
                                                            zIndex: 104
                                                        }}
                                                    />
                                                )}
                                                <img
                                                    src={widgetData.logo}
                                                    style={{
                                                        //maxWidth: innerWindowOpen ? 128 : 80,
                                                        //maxHeight: innerWindowOpen ? 110 : 40,
                                                        //marginTop: innerWindowOpen ? 70 : 6,
                                                        maxWidth: 80,
                                                        maxHeight: 40,
                                                        marginTop: 6,
                                                        animationDelay: '500ms',
                                                        transition: innerWindowOpen
                                                            ? 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)' // expanding
                                                            : 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)', // shrinking
                                                        position: innerWindowOpen ? 'absolute' : 'relative',
                                                        left: innerWindowOpen ? '55%' : '14px',
                                                        transform: innerWindowOpen ? 'translateX(-50%)' : 'translateX(0)',
                                                    }}
                                                    alt="logo"
                                                />
                                            </div>
                                        ) : (
                                            <h4
                                                style={{
                                                    fontSize: 17,
                                                    color: '#9f9f9ff6',
                                                    textAlign: 'center',
                                                    maxWidth: 300,
                                                    marginLeft: '20px',
                                                    marginTop: 16
                                                }}
                                            >
                                                {widgetData?.title}
                                            </h4>
                                        )}
                                    </div>
                                    <div
                                        className="e10snzb07 lc-1i4hm43 emevikl1"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            borderWidth: "initial",
                                            borderStyle: "initial",
                                            borderColor: "rgb(0, 0, 0)",
                                            color: "rgb(17, 17, 17)",
                                            fontWeight: 700,
                                            fontSize: 16,
                                            textAlign: "center",
                                            minHeight: 56,
                                            padding: "0.8em",
                                            backgroundColor: "transparent",
                                            top: 0,
                                            zIndex: 3,
                                            position: "relative",

                                        }}
                                    >
                                        <div
                                            className="lc-1q5pgrf emevikl0"
                                            style={{
                                                margin: "0px 0px 0px 32px",
                                                width: "100%",
                                                padding: "0px 2px",
                                                flexGrow: 1,
                                                fontSize: 14,
                                                overflow: "hidden"
                                            }}
                                        >
                                            <span
                                                className="lc-22bwi3 e10snzb06"
                                                style={{


                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    backgroundColor: "transparent"
                                                }}
                                            />
                                        </div>
                                        {
                                            innerWindowOpen &&
                                            <button
                                                type="button"
                                                aria-label="restart fönster"
                                                data-testid="restart"
                                                onClick={handleRestartChat}
                                                style={{
                                                    backgroundColor: "inherit"
                                                }}
                                            >
                                                <FaRedo style={{ marginTop: 3 }} size={20} color='#fff' />
                                            </button>
                                        }
                                        <button
                                            type="button"
                                            aria-label="Minimera fönster"
                                            data-testid="minimize"
                                            onClick={handleMinimize}
                                            className="ea5qvgz0 lc-ebkyw6 ejbfa1m0"
                                            style={{
                                                backgroundColor: "inherit"
                                            }}
                                        >
                                            <FaTimes style={{ marginTop: 3 }} size={20} color='#fff' />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="lc-1w7ftyl e1ckmroo0"
                                    style={{
                                        overflowY: "auto",
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        zIndex: 1,
                                        backgroundColor: "rgb(248, 248, 248)",
                                        backgroundColor: "white"
                                    }}
                                >
                                    <FloatingFooter activeColor={widgetData?.secondary_theme_color} isVisible={!innerWindowOpen} onChange={onChangeFooter} />
                                    <div
                                        className="lc-vrrmai e15rqx31"
                                        style={{


                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: 245,
                                            zIndex: 1
                                        }}
                                    >
                                        <div
                                            className="lc-zm9xlu ecmztab2"
                                            style={{


                                                position: "absolute",
                                                height: "100%",
                                                width: "100%",
                                                opacity: 1,
                                                backgroundPosition: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                                backgroundColor: "initial"
                                            }}
                                        />
                                        <div
                                            className="lc-1t20syh ecmztab1 backgroundImage"
                                            style={{
                                                position: "absolute",
                                                height: "100%",
                                                width: "100%",
                                                backgroundPosition: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                                backgroundColor: "initial",
                                                backgroundSize: "cover",  // Makes image cover the container
                                                backgroundRepeat: "no-repeat",  // Prevents image from repeating
                                                overflow: "hidden",  // Prevents content from spilling out
                                                left: 0,  // Ensures proper alignment
                                                right: 0,
                                                transform: innerWindowOpen ? 'scale(0.95)' : 'scale(1)',
                                                opacity: innerWindowOpen ? 0 : 1,
                                                transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
                                                ...(widgetData?.backgroundImageUrl && { backgroundImage: `url(${widgetData.backgroundImageUrl})` }),
                                                ...(widgetData?.backgroundImageCss || {})
                                            }}
                                        >
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            transform: `translateY(${innerWindowOpen ? '0' : '100%'})`,
                                            transition: 'transform 0.02s ease-out',
                                            height: '100%',
                                            backgroundColor: 'transparent',
                                            zIndex: innerWindowOpen ? 3 : -1,
                                            overflow: 'hidden',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {currentActiveWindow === 'Koti' &&
                                            <div style={{
                                                flex: 1,
                                                position: 'relative', // Added
                                                overflow: 'hidden',
                                            }}>
                                                <ChatSimple chatKey={chatKey} widgetData={widgetData} openingMessage={openingMessage} />
                                            </div>
                                        }
                                    </div>

                                    {currentActiveWindow === 'Yhteys' &&
                                        <div style={{ padding: 30, zIndex: 10, paddingTop: 0, minHeight: '100%', backgroundColor: "white" }}>
                                            {<ContactForm widgetData={widgetData} themeColor={widgetData?.theme_color} onGoBack={() => setSendMsg(false)} />
                                            }
                                        </div>
                                    }
                                    {currentActiveWindow === 'Apu' &&

                                        <div style={{ minHeight: '100%', background: "white", zIndex: 999 }}>
                                            <HelpCenter widgetData={widgetData} />
                                        </div>

                                    }


                                    {!innerWindowOpen && currentActiveWindow === 'Koti' &&
                                        <div
                                            id="homescreen-wrapper"
                                            className="lc-7f33bh e15rqx36"
                                            style={{
                                                minHeight: '100%',
                                                msOverflowStyle: "none", // IE and Edge
                                                scrollbarWidth: "none",  // Firefox
                                                overflowY: "scroll",    // Enable vertical scrolling
                                                "&::-webkit-scrollbar": {
                                                    display: "none"      // Chrome, Safari, newer versions of Opera
                                                },
                                                paddingBottom: 10,
                                                width: "100%",
                                                overflowY: "auto",
                                                flexGrow: 1,
                                                position: "relative",
                                                zIndex: 2
                                            }}
                                        >
                                            <div
                                                className="lc-1qf3q98 e15rqx30"
                                                style={{
                                                    marginTop: 64,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "baseline",
                                                    padding: "32px 16px 0px"
                                                }}
                                            >
                                                <h1
                                                    fontSize="2rem"
                                                    className="lc-vadyec ew75jgp1"
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 28,
                                                        textAlign: "left",
                                                        margin: 0,
                                                        wordBreak: "break-word",
                                                        zIndex: 1
                                                    }}
                                                >
                                                    <span
                                                        className="lc-16vzuxh ew75jgp0"
                                                        style={{
                                                            backgroundPosition: "initial",
                                                            backgroundOrigin: "initial",
                                                            backgroundColor: "rgb(17, 17, 17)",
                                                            backgroundClip: "text"
                                                        }}
                                                    >
                                                        {widgetData?.mainGreeting ?? 'Tervetuloa!'}
                                                    </span>
                                                </h1>
                                            </div>
                                            <div
                                                className="lc-8jt5tj e15rqx35"
                                                style={{


                                                    position: "relative",
                                                    zIndex: 2,
                                                    marginTop: 20
                                                }}
                                            >
                                                <div
                                                    className="lc-xittol e15rqx34"
                                                    style={{


                                                        padding: "0px 12px",
                                                        marginBottom: 16
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            color: '#6a6a6abf',
                                                            fontSize: '16px',
                                                            fontWeight: '500',
                                                            marginLeft: '24px',
                                                            marginBottom: '10px',
                                                            marginTop: '-12px',
                                                            padding: 2,
                                                            display: "none"
                                                        }}
                                                    >{widgetData?.primary_button_action_support_text}</p>
                                                </div>
                                                <div
                                                    className="lc-xittol e15rqx34"
                                                    style={{
                                                        padding: "0px 32px",
                                                        marginBottom: 16
                                                    }}
                                                >
                                                    <h2
                                                        className="lc-1qcaodq e184w8cj5"
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: "bold",
                                                            padding: "0px 8px",
                                                            marginTop: 24,
                                                            marginBottom: 16,
                                                            color: "rgb(17, 17, 17)"
                                                        }}
                                                    >
                                                        <div
                                                            className="lc-1laupg6 efrsfol0"
                                                            style={{

                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            {widgetData?.primary_button_action_support_text}
                                                        </div>
                                                    </h2>
                                                    <ul
                                                        data-testid="custom-links-list"
                                                        className="lc-8dnji7 e8ntqdz5"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            listStyleType: "none",
                                                            margin: 0,
                                                            padding: 0,
                                                            gap: 1
                                                        }}
                                                    >
                                                        {widgetData?.INITIAL_CHAT_OPENING_QUESTIONS?.map((item, index) => {
                                                            // Handle both old (string) and new (object) format
                                                            const text = typeof item === 'string' ? item : item.short_topic_button_title;
                                                            return <li
                                                                key={index}
                                                                className="lc-n2elx8 e8ntqdz4 hover-list-item"
                                                                style={{
                                                                    //backgroundColor: "black",
                                                                    borderBottom: '1px solid #042b602e',
                                                                    borderRadius: 12
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        setInnerWindowOpen(true);
                                                                        setOpeningMessage(item);
                                                                    }}
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    className="e8ntqdz3 lc-1edaskn ecc17ou0"
                                                                    style={{
                                                                        border: "1px solid #dfdfdf",
                                                                        wordBreak: "break-word",
                                                                        fontSize: 14,
                                                                        fontWeight: "normal",
                                                                        color: "#333333",
                                                                        padding: "12px 16px",
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        cursor: "pointer",
                                                                        fontWeight: 600,
                                                                        borderRadius: 12,
                                                                        background: widgetData?.useDarkTheme ? 'rgb(84 84 84)' : "rgb(249 249 249)",
                                                                        background: widgetData?.useDarkTheme ? '#fff' : "rgb(249 249 249)",

                                                                    }}
                                                                >
                                                                    <span
                                                                        className="lc-4gto0l e8ntqdz1"
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: 8
                                                                        }}
                                                                    >
                                                                        {text}
                                                                    </span>
                                                                    <FaComment style={{ color: widgetData?.secondary_theme_color ?? '#042b60' }} />
                                                                </div>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>


                                                <div
                                                    className="lc-xittol e15rqx34"
                                                    style={{
                                                        padding: "0px 16px",
                                                        marginBottom: 10,
                                                        paddingBottom: 48
                                                    }}
                                                >

                                                    {widgetData?.button_links?.length > 0 &&

                                                        <h2
                                                            className="lc-1qcaodq e184w8cj5"
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                padding: "0px 24px",
                                                                marginTop: 24,
                                                                color: "rgb(17, 17, 17)"
                                                            }}
                                                        >
                                                            <div
                                                                className="lc-1laupg6 efrsfol0 pikalinkitTitle"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                Pikalinkit
                                                                <FaSearch style={{ marginLeft: 6 }} />
                                                            </div>
                                                        </h2>
                                                    }
                                                    {widgetData?.button_links?.length > 0 &&
                                                        <div
                                                            data-testid="contact-card"
                                                            className="lc-br0dab eclvk762"
                                                            style={{
                                                                borderRadius: 12,
                                                                display: "flex",
                                                                overflow: "hidden auto",
                                                                flexDirection: "column",
                                                                maxWidth: "100%",
                                                                marginBottom: "0.1em",
                                                                width: "100%",
                                                                padding: 16
                                                            }}
                                                        >
                                                            <div
                                                                className="lc-14ugvnu eclvk761"
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    minWidth: "0%",
                                                                    maxWidth: "100%",
                                                                    color: "rgb(17, 17, 17)",
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    justifyContent: "space-between"
                                                                }}
                                                            >
                                                                {widgetData?.button_links?.map((link, index) => {
                                                                    const text = typeof link === 'string' ? link : link.text;
                                                                    const url = typeof link === 'string' ? '#' : (link.url || '#');
                                                                    const type = typeof link === 'string' ? 'link' : (link.type || 'link');

                                                                    // Get appropriate icon based on type
                                                                    const getIcon = (type) => {
                                                                        switch (type) {
                                                                            case 'tel':
                                                                                return faPhone;
                                                                            case 'email':
                                                                                return faEnvelope;
                                                                            case 'whatsapp':
                                                                                return faComment; // Alternative for WhatsApp
                                                                            case 'messenger':
                                                                                return faComments; // Alternative for Messenger
                                                                            case 'telegram':
                                                                                return faPaperPlane; // Alternative for Telegram
                                                                            default:
                                                                                return faExternalLink;
                                                                        }
                                                                    };

                                                                    // Get appropriate href based on type
                                                                    const getHref = (type, url) => {
                                                                        switch (type) {
                                                                            case 'tel':
                                                                                return `tel:${url}`;
                                                                            case 'email':
                                                                                return `mailto:${url}`;
                                                                            case 'whatsapp':
                                                                                return `https://wa.me/${url.replace(/[^0-9]/g, '')}`;
                                                                            case 'messenger':
                                                                                return `https://m.me/${url}`;
                                                                            case 'telegram':
                                                                                return `https://t.me/${url}`;
                                                                            default:
                                                                                return url;
                                                                        }
                                                                    };

                                                                    // Get confirmation message based on type
                                                                    const getConfirmMessage = (type) => {
                                                                        switch (type) {
                                                                            case 'tel':
                                                                                return `Soita numeroon ${url}?`;
                                                                            case 'email':
                                                                                return `Lähetä sähköpostia osoitteeseen ${url}?`;
                                                                            case 'whatsapp':
                                                                                return `Avaa WhatsApp-keskustelu?`;
                                                                            case 'messenger':
                                                                                return `Avaa Messenger-keskustelu?`;
                                                                            case 'telegram':
                                                                                return `Avaa Telegram-keskustelu?`;
                                                                            default:
                                                                                return `Siirry osoitteeseen ${url}?`;
                                                                        }
                                                                    };

                                                                    return (
                                                                        <button
                                                                            key={index}
                                                                            type="button"
                                                                            className="eclvk764 lc-rvkpez e898cdi0"
                                                                            style={{
                                                                                fontFamily: "inherit",
                                                                                textAlign: "center",
                                                                                overflow: "hidden",
                                                                                width: "100%",
                                                                                outlineOffset: 2,
                                                                                padding: 12,
                                                                                color: "rgb(255, 255, 255)",
                                                                                backgroundColor: widgetData?.quick_links_btn_color ?? '#007bff',
                                                                                gridTemplate:
                                                                                    '"icon cta chevron" min-content ". draft ." 1fr / 20px auto 20px',
                                                                                columnGap: 8,
                                                                                lineHeight: "16.8px",
                                                                                fontSize: 14,
                                                                                fontWeight: "bold",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "start",
                                                                                position: "relative",
                                                                                marginBottom: 4,
                                                                                borderWidth: "initial",
                                                                                borderStyle: "none",
                                                                                borderColor: "initial",
                                                                                borderRadius: 6,
                                                                            }}
                                                                            onClick={() => {
                                                                                if (window.confirm(getConfirmMessage(type))) {
                                                                                    window.location.href = getHref(type, url);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {text} <FontAwesomeIcon icon={getIcon(type)} style={{ marginLeft: "auto" }} />
                                                                        </button>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (widgetData?.displayReviews ?? true) &&
                                                        <div style={{ marginTop: 24 }}>
                                                            <ReviewWidget name={name} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

