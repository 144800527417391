import React, { useState } from 'react';
import { Form, Button, ButtonToolbar, Schema, Input, SelectPicker } from 'rsuite';
import { getApiUrl } from '../../config';

const { StringType } = Schema.Types;

const modelStep1 = Schema.Model({
    subject: StringType().isRequired('Aihe on pakollinen'),
    message: StringType().isRequired('Viesti on pakollinen'),
});

const modelStep2 = Schema.Model({
    name: StringType().isRequired('Nimi on pakollinen'),
    email: StringType()
        .isEmail('Tarkista sähköpostiosoite')
        .isRequired('Sähköposti on pakollinen'),
    phone: StringType()
});

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

const subjects = [
    { label: 'Lipunmyynti', value: 'tickets' },
    { label: 'Ottelutapahtuma', value: 'game_event' },
    { label: 'Yhteistyökumppanuus', value: 'partnership' },
    { label: 'Fanituotteet', value: 'merchandise' },
    { label: 'Media', value: 'media' },
    { label: 'Muu asia', value: 'other' }
].map(item => ({ label: item.label, value: item.value }));

const ContactForm = ({ onGoBack, themeColor, widgetData }) => {
    const [formValue, setFormValue] = useState({
        subject: '',
        message: '',
        name: '',
        email: '',
        phone: ''
    });
    const [step, setStep] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const form = React.useRef(null);

    const handleNextStep = () => {
        if (form.current.check()) {
            setStep(2);
        }
    };

    const handlePreviousStep = () => {
        setStep(1);
    };

    const submitForm = async () => {
        const payload = {
            widget_id: widgetData?.bot_id,
            notification_email: widgetData?.notification_emails,
            sender_info: {
                url: window.location.href,
                userAgent: navigator.userAgent,
            },
            ...{form_data : formValue}
        };

        const response = await fetch(getApiUrl() + '/widget/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error('Failed to submit form:', response.statusText);
        }
    };

    const handleSubmit = () => {
        if (form.current.check()) {
            submitForm();
            setSubmitted(true);
        }
    };

    return (
        <div style={{ padding: 16, background: "white",  minHeight:'100%', }}>
            {!submitted ? (
                <>
                    <h3 style={{ marginBottom: 8 }}>Miten voimme auttaa?</h3>
                    <p style={{
                        fontSize: '14px',
                        color: '#666',
                        marginBottom: 24
                    }}>
                        {step === 1 ? 'Kerro, miten voimme olla avuksi.' : 'Täytä yhteystietosi, niin olemme sinuun yhteydessä pian.'}
                    </p>

                    <Form
                        ref={form}
                        model={step === 1 ? modelStep1 : modelStep2}
                        formValue={formValue}
                        onChange={setFormValue}
                        onSubmit={step === 1 ? handleNextStep : handleSubmit}
                        fluid
                    >
                        {step === 1 ? (
                            <>
                                <Form.Group controlId="subject">
                                    <Form.ControlLabel>Aihe*</Form.ControlLabel>
                                    <SelectPicker
                                        name="subject"
                                        data={subjects}
                                        block
                                        placeholder="Valitse aihe"
                                        value={formValue.subject}
                                        menuStyle={{ zIndex: 999999999999 }}
                                        style={{ zIndex: 999999999999 }}
                                        container={() => document.body} // This is important - renders the menu at body level
                                        onChange={value => setFormValue({ ...formValue, subject: value })}
                                    />
                                </Form.Group>

                                <Form.Group controlId="message">
                                    <Form.ControlLabel>Viesti*</Form.ControlLabel>
                                    <Form.Control
                                        componentClass="textarea"
                                        rows={4}
                                        accepter={Textarea}
                                        name="message"
                                        placeholder="Kerro, miten voimme auttaa"
                                    />
                                </Form.Group>

                                <ButtonToolbar style={{ marginTop: 24 }}>
                                    <Button
                                        appearance="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: themeColor,
                                            borderColor: themeColor
                                        }}
                                    >
                                        Seuraava
                                    </Button>
                                </ButtonToolbar>
                            </>
                        ) : (
                            <>
                                <Form.Group controlId="name">
                                    <Form.ControlLabel>Nimi*</Form.ControlLabel>
                                    <Form.Control name="name" placeholder="Etunimi Sukunimi" />
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <Form.ControlLabel>Sähköposti*</Form.ControlLabel>
                                    <Form.Control name="email" type="email" placeholder="email@esimerkki.fi" />
                                </Form.Group>

                                <Form.Group controlId="phone">
                                    <Form.ControlLabel>Puhelin</Form.ControlLabel>
                                    <Form.Control name="phone" placeholder="040 1234567" />
                                </Form.Group>

                                <ButtonToolbar style={{ marginTop: 24 }}>
                                    <Button
                                        appearance="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: themeColor,
                                            borderColor: themeColor
                                        }}
                                    >
                                        Lähetä viesti
                                    </Button>
                                    <Button
                                        appearance="subtle"
                                        onClick={handlePreviousStep}
                                    >
                                        Takaisin
                                    </Button>
                                </ButtonToolbar>
                            </>
                        )}
                    </Form>
                </>
            ) : (
                <div style={{ textAlign: 'center', padding: '32px 16px' }}>
                    <h3 style={{ marginBottom: 16 }}>Kiitos viestistäsi!</h3>
                    <p style={{ marginBottom: 24 }}>
                        Olemme sinuun yhteydessä mahdollisimman pian.
                    </p>
                </div>
            )}
        </div>
    );
};

export default ContactForm;