export const API_URL = process.env.REACT_APP_API_URL;

const DEFAULT_API_URL = 'https://virtuaaliapuri.azurewebsites.net';

export const getApiUrl = () => {
    // Check if a custom API URL is provided in the widget initialization
    if (window.VirtuaaliApuriWidget?.config?.apiUrl) {
        return window.VirtuaaliApuriWidget.config.apiUrl;
    }

    // Check if running locally
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        return 'http://localhost:5000';  // or whatever your local API port is
    }

    return DEFAULT_API_URL;
};