import React, { useState, useEffect, useRef } from 'react';
import { Button, Avatar, Panel as RsuitePanel, Dropdown } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styled from 'styled-components';
import axios from 'axios';
import { getApiUrl } from '../../config';
import MultiStepForm from './MultiStepForm';

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  position: relative;
 max-height: calc(100% - ${props => props.buttonHeight || 0}px);
  -webkit-overflow-scrolling: touch;

  /* Modern scrollbar styling */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.0) transparent;
`;

/*
const Panel = styled(RsuitePanel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background:#d5d5d5;
  display: flex;
flex-direction: column;
height: 100%;
position: relative;
overflow: hidden;
`;
*/
const Panel = styled(RsuitePanel)`
  height: 100%;
  height: 100%; // Take up the full parent height
  overflow: hidden; // Prevent content overflow
  position: relative; // Ensure it positions relative to the parent
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #042b60;
  padding: 14px;
  z-index: 10;
  overflow-y: auto;
  transform: translateY(${props => props.isExiting ? '100%' : '0'});
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-in;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;
const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const AnimatedButton = styled.button`
  @keyframes popIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    70% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  padding: 12px 14px;
  background-color: ${props => props.themeColor || '#007bff'};
  border: none;
  border-radius: 32px;
  color: white;
  cursor: pointer;
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  min-width: 120px;
  max-width: 300px;
  opacity: 0;
  animation: popIn 0.5s ease-out forwards;
  animation-delay: ${props => props.delay + 10}ms; // Add 800ms to wait for container slide up
`;

const ChatMessage = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  margin-bottom: ${props => props.type === 'user' ? '10px' : '5px'};
  margin-top: ${props => props.type === 'user' ? '10px' : '5px'};
  display: flex;
  flex-direction: ${props => props.type === 'user' ? 'row-reverse' : 'row'};
  align-items: flex-start;
  gap: 10px;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.25s ease-out forwards;
  animation-delay: ${props => props.delay};
`;


const extractBaseURL = (url) => {
    try {
        // Find the position of first '/' after the protocol
        const protocolEnd = url.indexOf('//');
        if (protocolEnd !== -1) {
            const pathStart = url.indexOf('/', protocolEnd + 2);
            if (pathStart !== -1) {
                return url.substring(0, pathStart + 1);
            }
        }
        // If no path is found, return the original URL with trailing slash
        return url.endsWith('/') ? url : url + '/';
    } catch (error) {
        return '';
    }
};

const isColorDark = (color) => {
    // Handle null/undefined color
    if (!color) return false;

    // Convert hex to RGB
    let r, g, b;
    if (color.startsWith('#')) {
        const hex = color.replace('#', '');
        r = parseInt(hex.substr(0, 2), 16);
        g = parseInt(hex.substr(2, 2), 16);
        b = parseInt(hex.substr(4, 2), 16);
    } else if (color.startsWith('rgb')) {
        // Handle rgb/rgba format
        const values = color.match(/\d+/g);
        r = parseInt(values[0]);
        g = parseInt(values[1]);
        b = parseInt(values[2]);
    } else {
        return false;
    }

    // Calculate relative luminance using the formula
    // Darker colors have lower luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
}

const TypingIndicator = () => (
    <div style={{
        display: 'flex',
        gap: '5px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        width: 'fit-content'
    }}>
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.2s'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.4s'
        }} />
        <style>{`
      @keyframes bounce {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
      }
    `}</style>
    </div>
);

export const ChatAvatar = ({ logoUrl, defaultText = 'B', isVisible = true }) => {
    const [imageError, setImageError] = useState(false);
    const showDefaultText = !logoUrl || imageError;

    return (
        <Avatar
            circle
            size="sm"
            src={showDefaultText ? null : logoUrl}
            alt="Chat Avatar"
            style={{
                backgroundColor: defaultText === 'B' ? 'transparent' : 'lightgray',
                padding: '2px',
                border: '1px solid #bfbfbf',
                color: "#fff",
                visibility: isVisible ? 'visible' : 'hidden'
            }}
            onError={() => setImageError(true)}
        >
            {showDefaultText && defaultText}
        </Avatar>
    );
};

const saveToLocalStorage = (history) => {
    try {
        console.log('Saving chat history:11'); // Debug log
        const serializedHistory = JSON.stringify(history);
        console.log('Saving chat history:', serializedHistory); // Debug log
        localStorage.setItem('chatHistory', serializedHistory);
    } catch (error) {
        console.error('Error saving chat history:', error);
    }
};

const loadFromLocalStorage = () => {
    try {
        const savedHistory = localStorage.getItem('chatHistory');
        return savedHistory ? JSON.parse(savedHistory) : null;
    } catch (error) {
        console.error('Error loading chat history:', error);
        return null;
    }
};


const ChatSimple = ({ openingMessage, widgetData, chatKey }) => {
    const [messages, setMessages] = useState([]);
    const [currentOptions, setCurrentOptions] = useState(null);
    const [currentForm, setCurrentForm] = useState(null);
    const [allFormFields, setAllFormFields] = useState(null);
    const [currentStepId, setCurrentStepId] = useState(openingMessage?.topicId ?? "start");
    const [chatFlow, setChatFlow] = useState({});
    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutIds = useRef([]);
    const [isExitingButtons, setIsExitingButtons] = useState(false);
    const buttonContainerRef = useRef(null);
    const [buttonHeight, setButtonHeight] = useState(0);

    useEffect(() => {
        if (buttonContainerRef.current) {
            setButtonHeight(buttonContainerRef.current.offsetHeight);
        }
    }, [currentOptions, currentForm]);

    // Helper to create trackable timeouts
    const createTimeout = (callback, delay) => {
        const id = setTimeout(callback, delay);
        timeoutIds.current.push(id);
        return id;
    };

    // Clear all timeouts
    const clearAllTimeouts = () => {
        timeoutIds.current.forEach(id => clearTimeout(id));
        timeoutIds.current = [];
    };

    // Function to calculate delay based on message length
    const calculateDelay = (message) => {
        const baseDelay = 500; // Base delay in ms
        const delayPerChar = 20; // ms per character
        const maxDelay = 2000; // Maximum delay
        const delay = Math.min(baseDelay + message.length * delayPerChar, maxDelay);
        return delay;
    };

    useEffect(() => {
        //console.log(key)
        clearAllTimeouts();
        setMessages([]);
        setCurrentOptions(null);
        setCurrentForm(null);

        // Set the opening message as the first user message
        if (openingMessage?.short_topic_button_title) {
            setMessages([{
                type: 'user',
                content: openingMessage.short_topic_button_title,
                timestamp: new Date()
            }]);
        }

        proceedToNextStep(openingMessage?.topicId ?? "start");
        if (widgetData?.predefined_ai_chat_json?.length > 0) {
            var json = JSON.parse(widgetData?.predefined_ai_chat_json)
            console.log(json)
            setChatFlow(json)
        }
    }, [openingMessage, widgetData, chatKey]);

    const proceedToNextStep = async (stepId) => {
        return new Promise(async (resolve) => {
            setCurrentForm(null);
            setCurrentOptions(null);

            if (!stepId) {
                // Conversation ends
                resolve();
                return;
            }

            const nextStep = chatFlow[stepId];
            if (nextStep) {
                // Simulate typing indicator
                
                const sendMessage = async (content, type, timestamp) => {
                    setIsLoading(true);
                    const delay = calculateDelay(content);
                    await new Promise(resolve => createTimeout(resolve, delay));
                
                    setIsLoading(false);

                    setMessages(prev => [...prev, {
                        type,
                        content,
                        timestamp
                    }]);

                    await new Promise(resolve => createTimeout(resolve,300));
                    setIsLoading(true);
                };
                
                const sendMessagesSequentially = async (contentArray, type, timestamp) => {
                    for (const content of contentArray) {
                        await sendMessage(content, type, timestamp);
                    }
                };
                
                // Main logic
                const timestamp = new Date();
                if (Array.isArray(nextStep.content)) {
                    await sendMessagesSequentially(nextStep.content, nextStep.type, timestamp);
                } else {
                    await sendMessage(nextStep.content, nextStep.type, timestamp);
                }

                setIsLoading(true);
                await new Promise(resolve => createTimeout(resolve, 200));
                setIsLoading(false);
                setCurrentStepId(stepId);

                // Handle actions
                if (nextStep.action) {
                    if (nextStep.action.type === 'form') {
                        setCurrentForm(nextStep.action);
                        setCurrentOptions(null);
                        resolve();
                    } else if (nextStep.action.type === 'buttons') {
                        setCurrentOptions(nextStep.action.options);
                        setCurrentForm(null);
                        resolve();
                    } else if (nextStep.action.type === 'link') {
                        window.open(nextStep.action.url, '_blank');
                        await proceedToNextStep(nextStep.action.nextStep);
                        resolve();
                    }
                } else {
                    setCurrentForm(null);
                    setCurrentOptions(null);
                    // Proceed to next step if defined
                    if (nextStep.nextStep) {
                        await proceedToNextStep(nextStep.nextStep);
                        resolve();
                    } else {
                        resolve();
                    }
                }
            } else {
                // No more steps
                setCurrentForm(null);
                setCurrentOptions(null);
                setAllFormFields(null);
                resolve();
            }
        });
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    };

    useEffect(() => {
        const scrollAfterAdjustment = setTimeout(() => {
            scrollToBottom();
        }, 100); // Small delay to ensure layout recalculations

        return () => clearTimeout(scrollAfterAdjustment); // Clean up timeout
    }, [messages, currentForm, currentOptions, buttonHeight]);


    const handleFormSubmit = async (formData) => {
        // Add user's form data as a message
        setMessages(prev => [
            ...prev,
            {
                type: 'user',
                content: Object.entries(formData)?.length
                    ? (Object.entries(formData).slice(-1)[0]?.[1] || '-').toString()
                    : '-',
                timestamp: new Date(),
            },
        ]);
    
        await new Promise(resolve => createTimeout(resolve, 200));
    
        const submitForm = async (fields) => {
            const payload = {
                widget_id: widgetData?.bot_id,
                notification_email: widgetData?.notification_emails,
                sender_info: {
                    url: window.location.href,
                    userAgent: navigator.userAgent,
                },
                ...fields,
            };
    
            const response = await fetch(getApiUrl() + '/widget/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                console.error('Failed to submit form:', response.statusText);
            }
        };
    
        setAllFormFields(prevFields => {
            const updatedFields = {
                ...prevFields,
                [currentStepId]: formData, // Associate form data with the current step
            };
    
            if (currentForm?.does_submit) {
                submitForm({ form_data: updatedFields });
                return null; // Clear form fields after submission
            }
    
            return updatedFields;
        });
    
        // Proceed to the next step after updating state
        const nextStepId = currentForm?.nextStep;
        if (nextStepId) {
            proceedToNextStep(nextStepId);
        }
    };
    

    const handleOptionClick = async (option) => {
        setIsExitingButtons(true);
        setMessages(prev => [...prev, {
            type: 'user',
            content: option.text,
            timestamp: new Date()
        }]);

        await proceedToNextStep(option.nextStep);
        await new Promise(resolve => setTimeout(resolve, 200));
        setIsExitingButtons(false);
    };


    return (
        <Panel>
            <ChatContainer>
                <ScrollContainer buttonHeight={buttonHeight}>
                    {messages.map((message, index) => {
                        const prevMessage = messages[index - 1];
                        const isVisible = !prevMessage || prevMessage.type !== message.type;

                        return (
                            <ChatMessage
                                key={index}
                                delay={`${0.1}s`}
                                type={message.type}
                            >
                                {message.type !== 'user' &&
                                    <ChatAvatar
                                        isVisible={isVisible}
                                        logoUrl={message.type === 'user' ? null : widgetData?.logo}
                                        defaultText={message.type === 'user' ? 'U' : 'B'}
                                    />
                                }

                                <div
                                    style={{
                                        maxWidth: '70%',
                                        padding: '10px',
                                        position: 'relative',
                                        backgroundColor: message.type === 'user' ? widgetData?.secondary_theme_color : '#fff',
                                        borderRadius: '12px',
                                        borderTopRightRadius: message.type === 'user' ? '2px' : '12px',
                                        borderTopLeftRadius: message.type === 'user' ? '12px' : '2px',
                                        marginLeft: message.type === 'user' ? 'auto' : '0',
                                        border: '1px solid #d7d7d94d',
                                        fontWeight: message.type === 'user' ? 600 : '400',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#e6e6e6' : '#333333'
                                            : '#333333'
                                    }}
                                >
                                    <p style={{ margin: 0, whiteSpace: "pre-wrap", overFlow:"hidden", whiteSpace:"normal" }}>{message.content}</p>
                                    <small style={{
                                        fontSize: '0.7em',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#e6e6e6' : '#333333'
                                            : '#333333'
                                    }}>
                                        {message?.timestamp?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </small>
                                </div>
                            </ChatMessage>
                        )
                    })}
                    {isLoading && (
                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'flex-start'
                        }}>
                            <ChatAvatar logoUrl={widgetData?.logo} />
                            <TypingIndicator />
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </ScrollContainer>

                {currentOptions && (
                    <ButtonContainer ref={buttonContainerRef} isExiting={isExitingButtons} className="buttonContainer">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '10px',
                            width: '100%',
                            justifyContent: 'right'
                        }}>
                            {currentOptions.map((option, idx) => {
                                const nextStep = chatFlow[option?.nextStep];
                                const isExternalLink = nextStep?.action?.type === "link";

                                return (
                                    <AnimatedButton
                                        key={idx}
                                        className="selectionButton"
                                        onClick={() => handleOptionClick(option)}
                                        themeColor={widgetData?.theme_color}
                                        delay={idx * 100}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px'
                                        }}
                                        aria-label={isExternalLink ? `${option.text} (opens in new tab)` : option.text}
                                    >
                                        <span>{option.text}</span>
                                        {isExternalLink && (
                                            <span
                                                aria-hidden="true"
                                                style={{
                                                    fontSize: '0.85em',
                                                    marginLeft: '2px',
                                                    display: 'inline-flex'
                                                }}
                                            >
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z" />
                                                </svg>
                                            </span>
                                        )}
                                    </AnimatedButton>
                                )
                            })}
                        </div>
                    </ButtonContainer>
                )}


                {currentForm && (
                    <ButtonContainer ref={buttonContainerRef} className="buttonContainer">
                        <MultiStepForm formConfig={currentForm} onSubmit={handleFormSubmit} widgetData={widgetData} />
                    </ButtonContainer>
                )}
            </ChatContainer>
        </Panel>
    );
};
export default ChatSimple;