import React, { useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from 'rsuite'; // Modified imports

const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
};

const validateFinnishPhone = (phone) => {
    // Accepts formats: +358xxx, 0xxx, both with or without spaces/dashes
    // Minimum length after prefix: 6 digits, maximum: 12 digits
    const cleanPhone = phone.replace(/[\s-]/g, '');
    return cleanPhone.match(/^(?:\+358|0)([1-9]\d{5,11})$/);
};

const validatePostalCode = (postalCode) => {
    // Finnish postal code format: 5 digits
    return postalCode.match(/^\d{5}$/);
};

const validateName = (name) => {
    // At least 2 characters, letters, spaces, and scandinavian letters allowed
    return name.match(/^[a-zA-ZäöåÄÖÅ\s-]{2,}$/);
};

const getInputValidation = (type, value) => {
    if (!value) return null;

    switch (type) {
        case 'email':
            return validateEmail(value);
        case 'tel':
        case 'phone':
            return validateFinnishPhone(value);
        case 'postalcode':
            return validatePostalCode(value);
        case 'name':
            return validateName(value);
        default:
            return true;
    }
};

const formatPhoneNumber = (value) => {
    // Format phone number while typing
    if (!value) return value;
    const numbers = value.replace(/[^\d+]/g, '');
    if (numbers.startsWith('+358')) {
        return numbers.replace(/(\+358)(\d{2,3})(\d{3,4})(\d{0,3})/, '$1 $2 $3 $4').trim();
    }
    if (numbers.startsWith('0')) {
        return numbers.replace(/(\d{3})(\d{3,4})(\d{0,3})/, '$1 $2 $3').trim();
    }
    return numbers;
};

const Input = ({ field, index, formData, handleChange }) => {
    const isValid = getInputValidation(field.type, formData[field.name]);
    const showValidation = formData[field.name]?.length > 0;

    const getInputType = (fieldType) => {
        switch (fieldType) {
            case 'phone':
                return 'tel';
            case 'email':
                return 'email';
            case 'postalcode':
                return 'text';
            case 'number':
                return 'number';
            case 'date':
                return 'date';
            case 'password':
                return 'password';
            case 'checkbox':
                return 'checkbox';
            default:
                return 'text';
        }
    };

    const getBorderColor = () => {
        if (!showValidation) return '#ccc';
        return isValid ? '#4CAF50' : '#f44336';
    };

    const getErrorMessage = (type) => {
        switch (type) {
            case 'email':
                return 'Virheellinen sähköpostiosoite';
            case 'phone':
                return 'Virheellinen puhelinnumero (esim. +358 40 1234567 tai 040 1234567)';
            case 'postalcode':
                return 'Virheellinen postinumero (5 numeroa)';
            case 'name':
                return 'Virheellinen nimi (vähintään 2 kirjainta)';
            default:
                return 'Virheellinen arvo';
        }
    };

    const handleInputChange = (e) => {
        let value = e.target.value;

        // Apply specific formatting based on field type
        if (field.type === 'phone') {
            value = formatPhoneNumber(value);
        } else if (field.type === 'postalcode') {
            value = value.replace(/\D/g, '').substr(0, 5);
        } else if (field.type === 'name') {
            value = value.replace(/[^a-zA-ZäöåÄÖÅ\s-]/g, '');
        }

        handleChange({
            ...e,
            target: { ...e.target, value }
        }, field.name);
    };

    return (
        <div style={{ position: 'relative', width: '100%', marginBottom: '24px' }}>
            {field.label && (
                <label style={{
                    display: 'block',
                    marginBottom: '6px',
                    fontSize: '14px',
                    color: '#fff'
                }}>
                    {field.label}
                    {field.is_required && <span style={{ color: '#fff' }}> *</span>}
                </label>
            )}
            {
                field?.type === "checkbox" && <div>
                    Hi
                </div>
            }
            <input
                type={getInputType(field.type)}
                required={field.is_required}
                placeholder={field.placeholder || ""}
                autoFocus={index === 0}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                pattern={field.type === 'phone' ? "[+0-9 -]+" : undefined}
                maxLength={field.type === 'phone' ? 16 : undefined}
                style={{
                    width: "100%",
                    padding: "10px 12px",
                    borderRadius: "4px",
                    border: `2px solid ${getBorderColor()}`,
                    color: "black",
                    outline: "none",
                    transition: "all 0.3s ease",
                    backgroundColor: showValidation && !isValid ? '#fff2f2' : 'white',
                    fontSize: '16px',
                    boxSizing: 'border-box'
                }}
            />
            {showValidation && !isValid && (
                <div style={{
                    color: '#f44336',
                    fontSize: '12px',
                    marginTop: '4px',
                    position: 'absolute',
                    bottom: '-20px',
                    left: '0'
                }}>
                    {getErrorMessage(field.type)}
                </div>
            )}
        </div>
    );
};


const FormField = ({ field, index, formData, handleChange, themeColor }) => {
    if (field.type === "multiselect") {
        return (
            <MultiSelect
                themeColor={themeColor}
                field={field}
                formData={formData}
                handleChange={handleChange}
            />
        );
    }
    if (field.type === "textarea") {
        return (
            <div style={{ marginBottom: "24px" }}>
                <label style={{
                    display: 'block',
                    marginBottom: '6px',
                    fontSize: '14px',
                    color: '#fff'
                }}>
                    {field.label}
                    {field.is_required && <span style={{ color: '#fff' }}> *</span>}
                </label>
                <textarea
                    required={field.is_required}
                    placeholder={field.placeholder || ""}
                    value={formData[field.name] || ""}
                    autoFocus={index === 0}
                    onChange={(e) => handleChange(e, field.name)}
                    style={{
                        width: "100%",
                        padding: "10px 12px",
                        borderRadius: "4px",
                        border: "2px solid #ccc",
                        resize: "vertical",
                        color: "black",
                        minHeight: "100px",
                        fontSize: '16px',
                        boxSizing: 'border-box'
                    }}
                />
            </div>
        );
    }

    return (
        <Input
            field={field}
            index={index}
            formData={formData}
            handleChange={handleChange}
        />
    );
};


const MultiSelect = ({ field, formData, handleChange, themeColor }) => {
    const selectedOptions = formData[field.name] || [];

    const handleOptionChange = (value) => {
        let newSelected;
        if (selectedOptions.includes(value)) {
            newSelected = selectedOptions.filter(option => option !== value);
        } else {
            newSelected = [...selectedOptions, value];
        }

        handleChange({
            target: { value: newSelected }
        }, field.name);
    };

    return (
        <div style={{ marginBottom: '24px' }}>
            <ButtonToolbar>
                <ButtonGroup style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {field.options.map((option, index) => (
                        <Button
                            key={index}
                            appearance={selectedOptions.includes(option) ? "primary" : "default"}
                            onClick={() => handleOptionChange(option)}
                            className="formButton"

                            style={{
                                backgroundColor: selectedOptions.includes(option) ? themeColor ?? '#2196F3' : 'transparent',
                                color: selectedOptions.includes(option) ? '#fff' : '#fff',
                                border: '1px solid #fff',
                                marginBottom: '8px',
                                transition: 'all 0.3s ease'
                            }}
                        >
                            {option}
                        </Button>
                    ))}
                </ButtonGroup>
            </ButtonToolbar>
            {field.is_required && selectedOptions.length === 0 && (
                <div style={{
                    color: 'rgb(255 255 255 / 91%)',
                    fontSize: '13px',
                    marginTop: '4px'
                }}>
                    Valitse vähintään yksi vaihtoehto
                </div>
            )}
        </div>
    );
};

const MultiStepForm = ({ formConfig, onSubmit, widgetData }) => {
    const [formData, setFormData] = useState({});
  
    const handleChange = (e, fieldName) => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: e.target.value
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      
      // Validate required multiselect fields
      const isValid = formConfig.fields.every(field => {
        if (field.type === 'multiselect' && field.is_required) {
          const selectedOptions = formData[field.name] || [];
          return selectedOptions.length > 0;
        }
        return true;
      });
  
      if (isValid) {
        onSubmit(formData);
      }
    };
  
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "flex-start",
          background: "transparent",
          color: "#e5e5e5"
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            padding: "12px 18px",
            borderRadius: "16px",
            boxShadow: "rgba(149, 157, 165, 0.1) 0px 1px 1px"
          }}
        >
          {formConfig.fields.map((field, index) => (
            <FormField
              key={index}
              field={field}
              index={index}
              themeColor={widgetData?.theme_color}
              formData={formData}
              handleChange={handleChange}
            />
          ))}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              appearance="primary"
              type="submit"
              className="formButton"
              style={{
                backgroundColor: widgetData?.theme_color ?? "#2196F3",
                width: "100%",
                height:48,
                fontWeight:600
              }}
            >
              {formConfig.buttonText || (formConfig.does_submit && 'Lähetä ✓') || "Seuraava →"}
            </Button>
          </div>
        </form>
      </div>
    );
  };

export default MultiStepForm;