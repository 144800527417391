import { useState, useMemo } from "react";
import { Panel } from "rsuite";

function HelpCenter({ widgetData }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [feedbacks, setFeedbacks] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState('');

  /*
  const categories = [
    { id: "all", name: "Kaikki" },
    { id: "general", name: "Yleiset" },
    { id: "account", name: "Käyttäjätili" },
    { id: "billing", name: "Laskutus" },
  ];
  */
 const categories = [""];

  const filteredQuestions = useMemo(() => {
    return (widgetData?.faq_questions || [])
      .filter(item => {
        const question = typeof item === 'string' ? item : item.question;
        const answer = typeof item === 'string' ? '' : (item.answer || '');
        const category = typeof item === 'string' ? 'general' : (item.category || 'general');

        const matchesSearch = (question + answer)
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        const matchesCategory = selectedCategory === 'all' || category === selectedCategory;

        return matchesSearch && matchesCategory;
      });
  }, [widgetData, searchQuery, selectedCategory]);

  const handleFeedback = (id, isHelpful) => {
    setFeedbacks(prev => ({
      ...prev,
      [id]: isHelpful
    }));

    setFeedbackMessage('Kiitos palautteesta');

    // Add small delay before going back to the list
    setTimeout(() => {
      setSelectedQuestion(null);
      setFeedbackMessage('');
    }, 1000);
  };

  if (selectedQuestion !== null) {
    const item = filteredQuestions[selectedQuestion];
    const question = typeof item === 'string' ? item : item.question;
    const answer = typeof item === 'string' ?
      'Ja tästä löytyy vastaus asiakkaan kiperään kysymykseen.' :
      (item.answer || 'Ja tästä löytyy vastaus asiakkaan kiperään kysymykseen.');

    return (
      <Panel bordered style={{
        width: '100%',
        background: "white",
        padding: '32px',
        height: '100%',
      }}>
        <div style={{ marginBottom: '16px' }}>
          <button
            onClick={() => setSelectedQuestion(null)}
            style={{
              border: 'none',
              background: 'none',
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: '#666'
            }}
          >
            ← Takaisin
          </button>
        </div>

        <h3 style={{
          fontSize: '18px',
          marginBottom: '16px',
          color: '#111'
        }}>
          {question}
        </h3>

        <div style={{
          fontSize: '14px',
          lineHeight: '1.5',
          color: '#444',
          marginBottom: '24px'
        }}>
          {answer}
        </div>

        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '16px',
          background: 'white',
          borderTop: '1px solid #eee',
          textAlign: 'center',
          paddingBottom: 85
        }}>
          {feedbackMessage ? (
            <p style={{
              fontSize: '14px',
              color: '#4CAF50',
              marginBottom: '8px'
            }}>
              {feedbackMessage}
            </p>
          ) : (<>
          <p style={{
            fontSize: '14px',
            color: '#666',
            marginBottom: '8px'
          }}>
            Oliko tästä vastauksesta apua?
          </p>
          <div style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center'
          }}>
            <button
              onClick={() => handleFeedback(selectedQuestion, true)}
              style={{
                padding: '8px 16px',
                borderRadius: '16px',
                border: 'none',
                background: feedbacks[selectedQuestion] === true ? '#4CAF50' : '#f0f0f0',
                color: feedbacks[selectedQuestion] === true ? 'white' : '#666',
                cursor: 'pointer'
              }}
            >
              👍 Kyllä
            </button>
            <button
              onClick={() => handleFeedback(selectedQuestion, false)}
              style={{
                padding: '8px 16px',
                borderRadius: '16px',
                border: 'none',
                background: feedbacks[selectedQuestion] === false ? '#f44336' : '#f0f0f0',
                color: feedbacks[selectedQuestion] === false ? 'white' : '#666',
                cursor: 'pointer'
              }}
            >
              👎 Ei
            </button>
          </div></>
          )}
        </div>
      </Panel>
    );
  }

  return (
    <Panel style={{
      width: '100%',
      background: "white",
      padding: '24px',
      height: '100%',
      overflow: "scroll",
      paddingBottom: '75px',
      msOverflowStyle: 'none',  // IE and Edge
      scrollbarWidth: 'none',   // Firefox
      '&::-webkit-scrollbar': { // Chrome, Safari, Opera
        display: 'none'
      }
    }}>
      <h2 style={{
        fontSize: '20px',
        marginBottom: '12px',
        textAlign: 'center'
      }}>
        Usein kysytyt kysymykset
      </h2>

      <div style={{
        position: 'relative',
        marginBottom: '12px'
      }}>
        <input
          type="search"
          placeholder="Etsi vastausta kysymykseesi..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            paddingLeft: '32px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            fontSize: '14px',
            backgroundColor: '#f8f9fa'
          }}
        />
        <svg
          style={{
            position: 'absolute',
            left: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '16px',
            height: '16px',
            color: '#666'
          }}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>

      <div style={{
        display: 'flex',
        gap: '8px',
        overflowX: 'auto',
        padding: '4px',
        marginBottom: '12px'
      }}>
        {categories?.filter(c => c?.length > 0)?.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            style={{
              padding: '6px 12px',
              borderRadius: '16px',
              border: 'none',
              background: selectedCategory === category.id ? '#007AFF' : '#f0f0f0',
              color: selectedCategory === category.id ? 'white' : '#666',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              fontSize: '13px'
            }}
          >
            {category.name}
          </button>
        ))}
      </div>

      <div style={{ overflowY: 'auto', paddingBottom:75 }}>
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((item, index) => {
            const question = typeof item === 'string' ? item : item.question;

            return (
              <button
                key={index}
                onClick={() => setSelectedQuestion(index)}
                style={{
                  width: '100%',
                  padding: '12px',
                  textAlign: 'left',
                  border: 'none',
                  borderBottom: '1px solid #eee',
                  background: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span style={{
                  fontSize: '14px',
                  color: '#111'
                }}>
                  {question}
                </span>
                <svg
                  style={{
                    flexShrink: 0,
                    width: '16px',
                    height: '16px',
                    color: '#666'
                  }}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <polyline points="9 18 15 12 9 6" />
                </svg>
              </button>
            );
          })
        ) : (
          <div style={{
            textAlign: 'center',
            padding: '16px',
            color: '#666'
          }}>
            Ei hakutuloksia
          </div>
        )}
      </div>
    </Panel>
  );
}

export default HelpCenter;